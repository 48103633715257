@define-mixin contentCardBaseVariant $backgroundColor, $textColor, $backgroundHoverColor {
    background-color: $backgroundColor;
    color: $textColor;

    &.Variant--hasLink:hover {
        background-color: $backgroundHoverColor;
    }
}

.Variant--lightGreyPrimary,
.Variant--lightGreySecondary {
    @mixin contentCardBaseVariant var(--blackLighten60Color), currentColor, var(--blackLighten50Color);
}

.Variant--blackPrimary,
.Variant--blackSecondary {
    @mixin contentCardBaseVariant var(--blackLighten10Color), var(--whiteBaseColor), var(--blackLighten12Color);
}

.Variant--darkGreyPrimary,
.Variant--darkGreySecondary {
    @mixin contentCardBaseVariant var(--blackLighten50Color), currentColor, var(--blackLighten40Color);
}

.Variant--hasLink {
    cursor: pointer;
    transition: background-color 200ms;
}

.Variant--darkRed {
    @mixin contentCardBaseVariant var(--primaryHKBaseColor), var(--whiteBaseColor), var(--primaryHKDarken20Color);
}

.Variant--darkTransparent {
    @mixin contentCardBaseVariant transparent, var(--whiteBaseColor), rgb(var(--whiteBaseRgb) / 10%);
}

.Variant--lightTransparent {
    @mixin contentCardBaseVariant transparent, var(--blackBaseColor), rgb(var(--blackBaseRgb) / 10%);
}

.Variant--yellow {
    @mixin contentCardBaseVariant var(--support01HKBaseColor), currentColor, var(--support01HKDarken20Color);
}

.Variant--blue {
    @mixin contentCardBaseVariant var(--support03FKLighten20Color), currentColor, var(--support03FKBaseColor);
}

.Variant--legacy {
    @mixin contentCardBaseVariant var(--blackLighten60LegacyColor), var(--blackBaseLegacyColor),
        var(--blackLighten60LegacyColor);
}

.Variant--rounded {
    @mixin themeRoundedCornersMedium;
    overflow: hidden;
}

.Variant--stretched {
    @mixin stretchContent;
}

.Variant--shadowed {
    &:hover.Variant--hasLink {
        @mixin themeBoxShadowLarge;
    }
}
