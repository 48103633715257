@define-mixin circleButtonIconVariant $iconColor {
    & .CircleButtonIcon {
        @mixin svgIconColor $iconColor;
    }
}

.ContentCardLayout {
    padding: 48px 32px;

    @media (--desktop) {
        padding: 64px 48px 80px;
    }
}

.Title {
    @mixin h40;
    font-weight: 600;
    margin-bottom: 24px;

    @media (--tablet) {
        margin-bottom: 32px;
    }

    @media (--desktop) {
        @mixin h30;
    }
}

.Description {
    @mixin p20;
    @mixin lineClamp 6;
    margin-bottom: 24px;

    @media (--tablet) {
        @mixin p10;
        @mixin lineClamp 8;
        margin-bottom: 48px;
    }

    @media (--desktop) {
        @mixin lineClamp 6;
    }
}

.Link {
    width: 100%;

    @media (--tablet) {
        width: auto;
    }

    & .CircleButtonIcon {
        @mixin fixedRatioSvgIcon 40px;
        @mixin buttonTransitionBehavior translate;
    }
}

.Variant--legacy {
    @mixin circleButtonIconVariant var(--blackBaseLegacyColor);
    color: currentColor;
    background: var(--blackLighten60LegacyColor);

    & .Title {
        @mixin p30;
        @mixin resetLineUnder;
        font-weight: 500;
        font-family: var(--baseFontFamily);
        margin-bottom: 8px;

        @media (--tablet) {
            @mixin h40;
            margin-bottom: 16px;
        }
    }

    & .Description p,
    & .Description {
        @mixin p30;
        margin-bottom: 16px;

        @media (--mobileOnly) {
            @mixin label;
            font-weight: 400;
        }
    }

    &.Variant--small {
        padding: 24px 32px 40px;

        @media (--tablet) {
            padding: 24px 24px 32px;
        }

        & .Title {
            @mixin p30;
        }
    }

    &.Variant--medium {
        padding: 24px 32px 40px;

        & .Title {
            @mixin p30;
        }
    }

    &.Variant--large {
        padding: 24px 32px 32px;

        @media (--tablet) {
            padding: 32px 64px 48px 32px;
        }
    }
}

.Variant--lightGreyPrimary,
.Variant--lightGreySecondary {
    @mixin circleButtonIconVariant var(--blackLighten12Color);
}

.Variant--blackPrimary,
.Variant--blackSecondary {
    @mixin circleButtonIconVariant var(--whiteBaseColor);
}

.Variant--darkGreyPrimary,
.Variant--darkGreySecondary {
    @mixin circleButtonIconVariant currentColor;
}

.Variant--darkRed {
    @mixin circleButtonIconVariant var(--blackLighten60Color);
}

.Variant--darkTransparent {
    @mixin circleButtonIconVariant var(--whiteBaseColor);
}

.Variant--lightTransparent {
    @mixin circleButtonIconVariant var(--blackBaseColor);
}

.Variant--yellow {
    @mixin circleButtonIconVariant currentColor;
}

.Variant--blue {
    @mixin circleButtonIconVariant currentColor;
}

.Variant--stretched {
    @mixin stretchContent;

    & .Link {
        align-self: start;
        margin-top: auto;
        margin-bottom: 0;
    }
}

.CardWithLink:hover {
    & .CircleButtonIcon {
        translate: 8px 0;
    }
}
